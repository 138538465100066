const Icon = () => (
  <svg width="68px" height="62px" viewBox="0 0 68 62">
    <g id="Documents-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="A01" transform="translate(-461.000000, -1100.000000)">
        <g id="icon-4" transform="translate(461.000000, 1100.000000)">
          <rect id="Rectangle" fill="#0828CC" x="0" y="42" width="20" height="20"></rect>
          <rect id="Rectangle-Copy" fill="#1955CD" x="24" y="42" width="20" height="20"></rect>
          <rect id="Rectangle-Copy-5" fill="#FFFFFF" x="25" y="0" width="16" height="16"></rect>
          <rect id="Rectangle-Copy-3" fill="#369FCD" x="13" y="20" width="18" height="18"></rect>
          <rect id="Rectangle-Copy-4" fill="#1955CD" x="35" y="20" width="18" height="18"></rect>
          <rect id="Rectangle-Copy-2" fill="#369FCD" x="48" y="42" width="20" height="20"></rect>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
