const Icon = () => (
  <svg width="63px" height="70px" viewBox="0 0 63 70">
    <g id="Documents-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="A03" transform="translate(-474.000000, -622.000000)" strokeWidth="2.25">
        <g id="icon-11" transform="translate(468.000000, 622.000000)">
          <ellipse id="Oval" stroke="#FFFFFF" cx="37.1875" cy="35" rx="11.5625" ry="33.875"></ellipse>
          <ellipse
            id="Oval"
            stroke="#101CD5"
            transform="translate(37.187500, 35.000000) rotate(120.000000) translate(-37.187500, -35.000000) "
            cx="37.1875"
            cy="35"
            rx="11.5625"
            ry="33.875"
          ></ellipse>
          <ellipse
            id="Oval"
            stroke="#00A1D2"
            transform="translate(37.187500, 35.000000) rotate(240.000000) translate(-37.187500, -35.000000) "
            cx="37.1875"
            cy="35"
            rx="11.5625"
            ry="33.875"
          ></ellipse>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
