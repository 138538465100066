const Icon = () => (
  <svg width="72px" height="72px" viewBox="0 0 72 72">
    <g id="Documents-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="A01" transform="translate(-459.000000, -927.000000)">
        <g id="icon-3" transform="translate(459.000000, 927.000000)">
          <rect id="Rectangle-Copy-12" fill="#2D89CC" x="26" y="0" width="20" height="20"></rect>
          <rect id="Rectangle-Copy-13" fill="#1955CD" x="26" y="52" width="20" height="20"></rect>
          <rect id="Rectangle-Copy-14" fill="#FFFFFF" x="29" y="29" width="14" height="14"></rect>
          <rect id="Rectangle-Copy-10" fill="#1955CD" x="52" y="26" width="20" height="20"></rect>
          <rect id="Rectangle-Copy-11" fill="#369FCD" x="0" y="26" width="20" height="20"></rect>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
