const Icon = () => (
  <svg width="65px" height="72px" viewBox="0 0 65 72">
    <g id="Documents-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="A02" transform="translate(-484.000000, -649.000000)">
        <g id="icon-8" transform="translate(480.000000, 649.000000)">
          <circle id="Oval" fill="#369FCD" cx="36.4136031" cy="9.23076923" r="9.23076923"></circle>
          <circle id="Oval-Copy" fill="#FFFFFF" cx="36.4136031" cy="36.9230769" r="7.38461538"></circle>
          <circle
            id="Oval"
            fill="#1955CD"
            transform="translate(59.596437, 22.615385) rotate(60.000000) translate(-59.596437, -22.615385) "
            cx="59.596437"
            cy="22.6153846"
            r="9.23076923"
          ></circle>
          <circle
            id="Oval"
            fill="#369FCD"
            transform="translate(59.596437, 49.384615) rotate(120.000000) translate(-59.596437, -49.384615) "
            cx="59.596437"
            cy="49.3846154"
            r="9.23076923"
          ></circle>
          <circle
            id="Oval"
            fill="#0828CC"
            transform="translate(36.413603, 62.769231) rotate(180.000000) translate(-36.413603, -62.769231) "
            cx="36.4136031"
            cy="62.7692308"
            r="9.23076923"
          ></circle>
          <circle
            id="Oval"
            fill="#1955CD"
            transform="translate(13.230769, 49.384615) rotate(240.000000) translate(-13.230769, -49.384615) "
            cx="13.2307692"
            cy="49.3846154"
            r="9.23076923"
          ></circle>
          <circle
            id="Oval"
            fill="#0828CC"
            transform="translate(13.230769, 22.615385) rotate(300.000000) translate(-13.230769, -22.615385) "
            cx="13.2307692"
            cy="22.6153846"
            r="9.23076923"
          ></circle>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
