import colors from './theme.colors';
import styles from './theme.styles';

export const magicNumbers = {
  sectionPadding: '60px',
  ctaSectionHeight: '70vh',
  columnHeight: '102px',
  columnHeightLarge: '225px',
};

export default {
  // this enables the color modes feature
  // and is used as the name for the top-level colors object
  initialColorMode: 'default',
  colors,
  magicNumbers,
  /**
   * FONT
   */
  fonts: {
    body: 'SFProText-Regular, system-ui, sans-serif',
    heading: 'SFCompactDisplay-Bold, system-ui, sans-serif',
    thin: 'SFCompactDisplay-Thin, system-ui, sans-serif',
    semi: 'SFCompactDisplay-Semibold, system-ui, sans-serif',
    compact: 'SFCompactDisplay-Regular, system-ui, sans-serif',
    medium: 'SFProText-Medium, system-ui, sans-serif',
    italic: 'SFProText-Italic, system-ui, sans-serif',
    bold: 'SFProText-Bold, system-ui, sans-serif',
    display: 'SFProDisplay-Regular, system-ui, sans-serif',
  },
  fontSizes: [10, 16, 18, 20, 28, 40],
  fontWieghts: {
    bold: 'bold',
    normal: 'normal',
  },
  lineHeights: [],
  letterSpacings: {},

  // Breakpoints
  breakpoints: ['412px', '768px', '1024px', '1400px'],

  /**
   * VARIANTS
   */
  breakpointColors: {
    backgroundColor: ['blue', 'purple', 'red', 'pink', 'green'],
    bg: ['yellow', 'pink'],
  },

  sizr: {
    variant: 'breakpointColors',
    width: '100vw',
    height: '1rem',
    position: 'fixed',
    bottom: '0',
    zIndex: '99999',
    background: ['blue', 'purple', 'red', 'pink', 'green'],
  },

  heroGridRowHeight: ['16.6667vh', '16.6667vh', '16.6667vh', '210px', '225px'],

  // Fast hide/show variants
  mq: {
    show: {
      md: {
        display: ['none', 'none', 'block'],
      },
    },
    hide: {
      md: {
        display: ['block', 'block', 'none'],
      },
    },
  },

  /* Section Variants */
  invert: {
    bg: 'text',
    color: 'background',
  },
  backgroundImage: {
    backgroundColor: 'background',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
  buttons: {
    primary: {
      padding: '8px',
      bg: 'primary',
      color: 'text',
    },
  },

  // TEXT
  text: {
    // Reusables
    header: {
      fontFamily: 'heading',
      fontWeight: 'bold',
    },
    light: {
      fontFamily: 'display',
      fontWeight: 'normal',
    },
    // Map to HTML entities
    h2: {
      variant: 'text.header',
      fontSize: [4, 4, 4, 4, 5], // 28
      textTransform: 'lowercase',
      letterSpacing: '-0.39px',
      lineHeight: '1.1',
      marginBottom: '40px',
      // maxWidth: '60%',
      maxWidth: ['100%', '100%', '100%', '350px'],
    },
    h3: {
      variant: 'text.header',
      fontSize: [3], // 20
      letterSpacing: '0px',
      lineHeight: '24px',
      marginBottom: '12px',
    },
    h4: {
      fontSize: [2], // 18
      fontFamily: 'medium',
      fontWeight: '500',
      letterSpacing: '-0.6px',
    },
    h5: {
      fontSize: [0, 0, 0, '12px'],
      fontFamily: 'bold',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      letterSpacing: '1.67px',
    },
    paragraph: {
      color: 'inherit',
      fontSize: [1], // '16px'
      fontFamily: 'body',
      fontWeight: 'normal',
      letterSpacing: '-0.1px',
      lineHeight: '26px',
    },
    removeLinkStyles: {
      color: 'initial',
      textDecoration: 'none',
    },
    pageNavLink: {
      // abstract
      color: 'background',
      fontSize: '14px',
      fontFamily: 'bold',
      fontWeight: 'bold',
      textTransform: 'lowercase',
      letterSpacing: '0px',
      lineHeight: '12px',
      // more
      display: 'block',
      textDecoration: 'none',
      py: ['12px', '24px', '36px'],
      px: '24px',
      textAlign: 'left',
    },
    docNavLinkText: {
      color: 'background',
      fontSize: '14px',
      fontFamily: 'bold',
      fontWeight: 'bold',
      textTransform: 'lowercase',
      letterSpacing: '0px',
      lineHeight: '12px',
      display: 'block',
      textDecoration: 'none',
      textAlign: 'left',
    },
    docNavLinkTextChild: {
      variant: 'text.docNavLinkText',
      fontFamily: 'body',
      fontWeight: 'normal',
    },
    quote: {
      text: {
        // color: 'background',
        fontSize: '30px',
        fontFamily: 'thin',
        fontWeight: '100',
        letterSpacing: '0px',
        lineHeight: '38px',
        marginBottom: '28px',
      },
      em: {
        fontFamily: 'italic',
        fontWeight: 'italic',
      },
      author: {
        // color: 'background',
        fontSize: '14px',
        fontFamily: 'semi',
        fontWeight: '600',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        marginBottom: '0',
      },
    },
  },
  styles,
  // variants
};
/**
 * @DONE
 *  @todo
 * TYPOGRAPHY
 * h3 / Reractor typography
 * OTHER
 * refactor existing components to use theme values
 */
