const Icon = () => (
  <svg width="28px" height="72px" viewBox="0 0 28 72">
    <g id="Documents-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="A02" transform="translate(-503.000000, -991.000000)">
        <g id="icon-6" transform="translate(503.000000, 991.000000)">
          <circle
            id="Oval"
            fill="#101CD5"
            transform="translate(14.000000, 58.000000) rotate(180.000000) translate(-14.000000, -58.000000) "
            cx="14"
            cy="58"
            r="14"
          ></circle>
          <circle
            id="Oval-Copy-3"
            fill="#00A3D7"
            transform="translate(14.000000, 30.000000) rotate(180.000000) translate(-14.000000, -30.000000) "
            cx="14"
            cy="30"
            r="10"
          ></circle>
          <circle id="Oval-Copy" fill="#FFFFFF" cx="14" cy="8" r="8"></circle>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
