/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { MDXProvider } from '@mdx-js/react';
import React from 'react';
// import { Icon1 } from '../../assets/system-icons';
import Icon from '../../assets/system-icons';
// const FooBlock = style

const Foo = ({ title, children, icon, open = false }) => (
  <div
    sx={{
      // bg: 'debug',
      display: 'flex',
      alignItems: 'flex-start',
    }}
  >
    {/* image */}
    <div
      sx={{
        mt: `calc(24px + 12px)`,
        width: '72px',
        height: '72px',
        marginRight: '80px',
        flex: '0 0 72px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '> svg': {
          maxWidth: '100%',
          height: 'auto',
        },
      }}
    >
      <Icon name={icon} />
    </div>
    <div>
      <Styled.h3>{title}</Styled.h3>
      {open && children}
      {!open && <Styled.p>{children}</Styled.p>}
    </div>
  </div>
);

// Foo.defaultProps = {
//   title: 'Fake Title',
//   text: "We empower every designer to give their best and collaborate efficiently. A distributed ownership helps keep the
//   system easily understandable and truly adaptable for others use."
// }

export default Foo;

export const Eg = ({ pos, neg }) => (
  <>
    <Styled.p sx={{ fontFamily: 'italic' }}>
      {`Eg. `}
      <span sx={{ color: '#0091FF' }}>{pos}</span>
      <br />
      <span sx={{ color: '#FF6060', textDecoration: 'line-through' }}>{neg}</span>
    </Styled.p>
  </>
);

Eg.defaultProps = {
  pos:
    'Give your agents an omnichannel and real-time view of conversations on social, all at one place, and create personalized user experiences backed by our intelligence.',
  neg: 'View conversations on social and help your agents serve your customers.',
};
