const Icon = () => (
  <svg width="65px" height="53px" viewBox="0 0 65 53">
    <g
      id="Documents-Page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="A03" transform="translate(-473.000000, -1040.000000)" strokeWidth="2.25">
        <g id="icon-9" transform="translate(475.000000, 1041.000000)">
          <g id="Group-3">
            <path d="M0,13.0609756 L49.0392157,13.0609756" id="Line-3-Copy-11" stroke="#FFFFFF"></path>
            <path d="M19.1372549,0.62195122 L61,0.62195122" id="Line-3-Copy-7" stroke="#00A1D2"></path>
            <path d="M0,0.62195122 L11.9607843,0.62195122" id="Line-3-Copy-7" stroke="#101CD5"></path>
            <path d="M0,25.5 L25.1176471,25.5" id="Line-3-Copy-8" stroke="#00A1D2"></path>
            <path d="M0,37.9390244 L40.6666667,37.9390244" id="Line-3-Copy-9" stroke="#101CD5"></path>
            <path d="M49.0392157,37.9390244 L61,37.9390244" id="Line-3-Copy-7" stroke="#00A1D2"></path>
            <path d="M0,50.3780488 L25.1176471,50.3780488" id="Line-3-Copy-12" stroke="#0052D5"></path>
            <path d="M32.2941176,26.1219512 L61,26.1219512" id="Line-3-Copy-8" stroke="#0091FF"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
