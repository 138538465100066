const gallerySrc =
  'https://gallery-prod2.sprinklr.com/clients/734/embeds/ZW1iZWQ6NWRkZGE1OTc4ZTZmMjc2NzE1YjM0OWQz/script.js';

const insertGalleryScript = () => {
  const addJS = src => {
    const s = document.createElement(`script`);
    s.type = `text/javascript`;
    s.src = src;
    s.id = 'sprinklr-embed-loader-734-ZW1iZWQ6NWRkZGE1OTc4ZTZmMjc2NzE1YjM0OWQz';
    document.getElementsByTagName(`head`)[0].appendChild(s);
  };

  addJS(gallerySrc);
};

exports.onInitialClientRender = () => {
  insertGalleryScript();
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  // console.log('new pathname', location.pathname);
  // console.log('old pathname', prevLocation ? prevLocation.pathname : null);
  insertGalleryScript();
};
