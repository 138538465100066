const baseColors = {
  black: '#0a0a14',
  white: '#fff',
  W000_60: 'rgba(255, 255, 255, 0.6)',
  greyText: '#4c4c53',
  purples: ['#6c31c9', '#a13ee0', '#ec97ff', '#3123ae'],
  blues: ['#0828cc', '#1955cd', '#369fcd', '#2d89cc'],
  oranges: ['#ff814b', '#fc9748', '#f9ae46', '#f6c243'],
  greens: ['#4ebd5b', '#aad762', '#79c95e', '#d0e163', '#319750'],
  greys: ['#53535a', '#22222b', '#3b3b43', '#47474e', '#2e2e37'],
}

const colors = {
  text: baseColors.black,
  bodyText: baseColors.greyText,
  background: baseColors.white,
  background90: 'rgb(247, 249, 253)',
  background60: baseColors.W000_60,
  primary: baseColors.blues[0],
  primaryAlt: baseColors.blues[2],
  secondary: baseColors.oranges[1],
  tertiary: baseColors.purples[1],
  accent: baseColors.greens[1],
  debug: 'crimson',

  scale: {
    primary: baseColors.blues,
    secondary: baseColors.oranges,
    tertiary: baseColors.purples,
    accent: baseColors.greens,
    neutral: baseColors.greys,
  },

  modes: {
    alt: {
      text: baseColors.white,
      background: baseColors.black,
    },
  },
}

export default colors
export { baseColors }
