const Icon = () => (
  <svg width="58px" height="58px" viewBox="0 0 58 58">
    <g id="Documents-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="A02" transform="translate(-488.000000, -834.000000)">
        <g id="icon-7" transform="translate(488.000000, 834.000000)">
          <circle
            id="Oval"
            fill="#0828CC"
            transform="translate(24.000000, 24.000000) rotate(180.000000) translate(-24.000000, -24.000000) "
            cx="24"
            cy="24"
            r="24"
          ></circle>
          <circle id="Oval-Copy" fill="#FFFFFF" cx="50" cy="50" r="8"></circle>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
