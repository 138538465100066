const Icon = () => (
  <svg width="84px" height="52px" viewBox="0 0 84 52">
    <g id="Documents-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="A02" transform="translate(-475.000000, -1175.000000)">
        <g id="icon-5" transform="translate(475.000000, 1175.000000)">
          <circle
            id="Oval-Copy-4"
            fill="#101CD5"
            transform="translate(10.000000, 42.000000) rotate(90.000000) translate(-10.000000, -42.000000) "
            cx="10"
            cy="42"
            r="10"
          ></circle>
          <circle
            id="Oval-Copy-6"
            fill="#0052D5"
            transform="translate(42.000000, 42.000000) rotate(90.000000) translate(-42.000000, -42.000000) "
            cx="42"
            cy="42"
            r="10"
          ></circle>
          <circle
            id="Oval-Copy-5"
            fill="#00A1D2"
            transform="translate(10.000000, 10.000000) rotate(90.000000) translate(-10.000000, -10.000000) "
            cx="10"
            cy="10"
            r="10"
          ></circle>
          <circle
            id="Oval-Copy-7"
            fill="#FFFFFF"
            transform="translate(42.000000, 10.000000) rotate(90.000000) translate(-42.000000, -10.000000) "
            cx="42"
            cy="10"
            r="10"
          ></circle>
          <circle
            id="Oval-Copy-9"
            fill="#00A1D2"
            transform="translate(74.000000, 42.000000) rotate(90.000000) translate(-74.000000, -42.000000) "
            cx="74"
            cy="42"
            r="10"
          ></circle>
          <circle
            id="Oval-Copy-8"
            fill="#0052D5"
            transform="translate(74.000000, 10.000000) rotate(90.000000) translate(-74.000000, -10.000000) "
            cx="74"
            cy="10"
            r="10"
          ></circle>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
