const Icon = () => (
  <svg width="63px" height="67px" viewBox="0 0 63 67">
    <g
      id="Documents-Page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g id="A03" transform="translate(-474.000000, -824.000000)" strokeWidth="2.25">
        <g id="icon-10" transform="translate(474.000000, 826.000000)">
          <path d="M21.2,0 L21.2,63" id="Line-3-Copy-2" stroke="#FFFFFF"></path>
          <path d="M11.2,12.8333333 L11.2,51.3333333" id="Line-3-Copy" stroke="#0052D5"></path>
          <path d="M31.2,12.8333333 L31.2,51.3333333" id="Line-3-Copy-3" stroke="#00A1D2"></path>
          <path d="M51.2,12.8333333 L51.2,51.3333333" id="Line-3-Copy-4" stroke="#0052D5"></path>
          <path d="M41.2,21 L41.2,42" id="Line-3-Copy-5" stroke="#101CD5"></path>
          <path d="M61.2,21 L61.2,42" id="Line-3-Copy-6" stroke="#00A1D2"></path>
          <path d="M1.2,21 L1.2,42" id="Line-3" stroke="#00A1D2"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
