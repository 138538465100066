/** @jsx jsx */
import { jsx } from 'theme-ui';
import icon from '../../assets/docs/button/icon.png';
import iconText from '../../assets/docs/button/icon-text.png';
import primary from '../../assets/docs/button/primary.png';
import secondary from '../../assets/docs/button/secondary.png';
import sizes from '../../assets/docs/button/sizes.png';
import spacing from '../../assets/docs/button/spacing.png';
import tertiary from '../../assets/docs/button/tertiary.png';
import textLink from '../../assets/docs/button/text-link.png';
import usage from '../../assets/docs/button/usage.png';

const buttonImageMap = {
  icon,
  iconText,
  primary,
  secondary,
  sizes,
  spacing,
  tertiary,
  textLink,
  usage,
};

export const FakeImage = ({ imageKey = 'primary' }) => {
  return (
    <div
      sx={{
        // border: '2px solid green',
        width: '100%',
        overflow: 'scroll',
        position: 'relative',
        img: {
          maxWidth: 'none',
        },
      }}
    >
      <img src={buttonImageMap[imageKey]} alt={`Button: ${imageKey}`} />
    </div>
  );
};

const Fake = () => <img src={fake} alt="This is fake" />;

export default Fake;
