// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-system-component-library-mdx": () => import("./../../../src/pages/system/component-library.mdx" /* webpackChunkName: "component---src-pages-system-component-library-mdx" */),
  "component---src-pages-system-content-guidelines-mdx": () => import("./../../../src/pages/system/content/guidelines.mdx" /* webpackChunkName: "component---src-pages-system-content-guidelines-mdx" */),
  "component---src-pages-system-content-style-mdx": () => import("./../../../src/pages/system/content/style.mdx" /* webpackChunkName: "component---src-pages-system-content-style-mdx" */),
  "component---src-pages-system-content-tone-mdx": () => import("./../../../src/pages/system/content/tone.mdx" /* webpackChunkName: "component---src-pages-system-content-tone-mdx" */),
  "component---src-pages-system-content-voice-mdx": () => import("./../../../src/pages/system/content/voice.mdx" /* webpackChunkName: "component---src-pages-system-content-voice-mdx" */),
  "component---src-pages-system-foo-bar-baz-bar-mdx": () => import("./../../../src/pages/system/fooBarBaz/bar.mdx" /* webpackChunkName: "component---src-pages-system-foo-bar-baz-bar-mdx" */),
  "component---src-pages-system-foo-bar-baz-foo-mdx": () => import("./../../../src/pages/system/fooBarBaz/foo.mdx" /* webpackChunkName: "component---src-pages-system-foo-bar-baz-foo-mdx" */),
  "component---src-pages-system-principles-mdx": () => import("./../../../src/pages/system/principles.mdx" /* webpackChunkName: "component---src-pages-system-principles-mdx" */),
  "component---src-pages-system-visual-guidelines-mdx": () => import("./../../../src/pages/system/visual-guidelines.mdx" /* webpackChunkName: "component---src-pages-system-visual-guidelines-mdx" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-thinking-js": () => import("./../../../src/pages/thinking.js" /* webpackChunkName: "component---src-pages-thinking-js" */)
}

