const Icon = () => (
  <svg width="80px" height="56px" viewBox="0 0 80 56">
    <g id="Documents-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="A01" transform="translate(-455.000000, -1269.000000)">
        <g id="icon-2" transform="translate(455.000000, 1269.000000)">
          <rect id="Rectangle-Copy-17" fill="#1955CD" x="36" y="36" width="20" height="20"></rect>
          <rect id="Rectangle-Copy-21" fill="#0828CC" x="12" y="36" width="20" height="20"></rect>
          <rect id="Rectangle-Copy-19" fill="#369FCD" x="60" y="36" width="20" height="20"></rect>
          <rect id="Rectangle-Copy-18" fill="#369FCD" x="36" y="12" width="20" height="20"></rect>
          <rect id="Rectangle-Copy-22" fill="#FFFFFF" x="0" y="0" width="16" height="16"></rect>
          <rect id="Rectangle-Copy-20" fill="#1955CD" x="60" y="12" width="20" height="20"></rect>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
