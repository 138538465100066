import Icon1 from './icon-1';
import Icon2 from './icon-2';
import Icon3 from './icon-3';
import Icon4 from './icon-4';
import Icon5 from './icon-5';
import Icon6 from './icon-6';
import Icon7 from './icon-7';
import Icon8 from './icon-8';
import Icon9 from './icon-9';
import Icon10 from './icon-10';
import Icon11 from './icon-11';

const Icon = props => {
  switch (props.name) {
    case 1:
      return <Icon1 />;
    case 2:
      return <Icon2 />;
    case 3:
      return <Icon3 />;
    case 4:
      return <Icon4 />;
    case 5:
      return <Icon5 />;
    case 6:
      return <Icon6 />;
    case 7:
      return <Icon7 />;
    case 8:
      return <Icon8 />;
    case 9:
      return <Icon9 />;
    case 10:
      return <Icon10 />;
    case 11:
      return <Icon11 />;
    default:
  }
};

Icon.defaultProps = {
  name: 1,
};

export default Icon;
