const Icon = () => (
  <svg width="76px" height="68px" viewBox="0 0 76 68">
    <g id="Documents-Page" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="A01" transform="translate(-457.000000, -1432.000000)">
        <g id="icon-1" transform="translate(457.000000, 1432.000000)">
          <rect id="Rectangle-Copy-23" fill="#FFFFFF" x="0" y="48" width="16" height="16"></rect>
          <rect id="Rectangle-Copy-24" fill="#1955CD" x="27" y="48" width="18" height="18"></rect>
          <rect id="Rectangle-Copy-26" fill="#369FCD" x="56" y="48" width="20" height="20"></rect>
          <rect id="Rectangle-Copy-25" fill="#369FCD" x="27" y="26" width="18" height="18"></rect>
          <rect id="Rectangle-Copy-27" fill="#0828CC" x="56" y="24" width="20" height="20"></rect>
          <rect id="Rectangle-Copy-28" fill="#1955CD" x="56" y="0" width="20" height="20"></rect>
        </g>
      </g>
    </g>
  </svg>
);

export default Icon;
