export default {
  p: {
    variant: 'text.paragraph',
  },
  h2: {
    variant: 'text.h2',
  },
  h3: {
    variant: 'text.h3',
  },
  h4: {
    variant: 'text.h4',
  },
  h5: {
    variant: 'text.h5',
  },
  em: {
    variant: 'text.paragraph',
    fontStyle: 'italic',
  },
  // em: {
  //   color: 'primary',
  // },
  li: {
    fontFamily: 'body',
  },
  a: {
    color: '#00a3d7',
    textDecoration: 'none',
    '&:visited': {},
  },
};
